define("ember-cli-deploy-sentry/services/raven", ["exports", "ember", "ember-cli-sentry/services/raven"], function (_exports, _ember, _raven) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _raven.default.extend({
    releaseMetaName: 'sentry:revision',
    release: _ember.default.computed('releaseMetaName', {
      get: function () {
        return document.querySelector(`meta[name='${this.get('releaseMetaName')}']`).content;
      }
    })
  });
  _exports.default = _default;
});